mb-10 {
    margin-bottom: 10px;
}

.pb-10 {
    padding-bottom: 10px;
}

textarea {
    resize: none;
}

.monospaced {
    font-family: Consolas, Courier New,Courier,Lucida Sans Typewriter,Lucida Typewriter,monospace;
}

h4.monospaced {
    margin-left: 15px;
    margin-right: 15px;
}

.highlighted {
    background-color: #ccc;
}

.ml-5 {
    margin-left: 5px;
}

.mr-5 {
    margin-right: 5px;
}

.overlay-carregando {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #353030;
    z-index: 999999999;
    display: flex;
    align-items: center;
    justify-content: center;

}

.carregando {
    font-size: 70px;
    color: white;
    animation: rodar cubic-bezier(0, 0, 1, 1) 100s infinite;
}
#topnav .navigation-menu > li > a {
    display:flex;
    align-items: center;
}

#topnav .navigation-menu > li > a > i {
    font-size: 20px;
}

#topnav .topbar-main .navbar-right .dropdown.open a.dropdown-toggle,
#topnav .topbar-main .navbar-right .dropdown.show a.dropdown-toggle,
#topnav .topbar-main .navbar-right .dropdown a.dropdown-toggle:hover {
    background-color: #5a5a5a !important;
}

.bloqueado {

    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.3);
    top: 0;
    left: 0px;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    text-align: center;
    justify-content: center;
    flex: 1;
    z-index: 9;

}

.bloqueado > span {
    font-size: 75px;
}

.alert-success > h3, .alert-success > h2 {
    color: #5b923e !important;
}

.dashboard .media .mdi,
.dashboard .media em{
    width: 36px;
    color: #ffffff;
    text-align: center;
    height: 36px;
    line-height: 36px;
    border-radius: 50%;
    margin-top: 2px;
    font-size: 16px;
}

.dashboard .media .media-left {
    margin-right: 15px;
    margin-top: 10px;
}

.form-control-slim {
    height: auto;
    padding: 2px 5px;
}

.clickable {
    cursor: pointer;
}


.progress {
    background-color: #e8e8e8;
}

.widget-box-three .bg-icon {
    border:none;
}

.erro {
    color: #f7531f !important;
    font-weight: bold;
    text-decoration: underline;
}

.btn-light {
    color: #212529;
    background-color: #eef1f3;
    border-color: #dae0e5;
}

.bg-cinza-claro {
    background-color:#808080;
}

.icone-colorido-barra-superior {
    height: 21px;
    width: 48px;
    margin: 0.4rem;
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show>.btn-light.dropdown-toggle,
.btn-light:hover {
    background-color: #dae0e5;
    border-color: #dae0e5;
}

.bg-transparent, .tabs-bordered .nav-item .nav-link.bg-transparent {
	background-color: transparent !important;
}

.objetivos-desafio {
	padding: 15px;
	width: 300px;
}

.objetivos-desafio > * {
	font-size: 14px;
}

.page-title-box {
    padding: 25px 10px 10px 10px;
}

.m-l-25 {
	margin-left: 25px !important;
}

.concluido {
	font-size: 15px;
	transform: rotate(5deg);
	position: absolute;
	top: -14px;
	left: 10px;
	box-shadow: 2px 3px rgba(0,0,0,.3);
}

.fa-4x {
    font-size: 4em;
}


@keyframes rodar {
    0% {transform:rotate(0deg);}
    100% {transform:rotate(36000deg);}
}

.pagination{display:inline-block;padding-left:0;margin:20px 0;border-radius:4px}.pagination>li{display:inline}.pagination>li>a,.pagination>li>span{position:relative;float:left;padding:6px 12px;margin-left:-1px;line-height:1.42857143;color:#428bca;text-decoration:none;background-color:#fff;border:1px solid #ddd}.pagination>li:first-child>a,.pagination>li:first-child>span{margin-left:0;border-top-left-radius:4px;border-bottom-left-radius:4px}.pagination>li:last-child>a,.pagination>li:last-child>span{border-top-right-radius:4px;border-bottom-right-radius:4px}.pagination>li>a:hover,.pagination>li>span:hover,.pagination>li>a:focus,.pagination>li>span:focus{color:#2a6496;background-color:#eee;border-color:#ddd}.pagination>.active>a,.pagination>.active>span,.pagination>.active>a:hover,.pagination>.active>span:hover,.pagination>.active>a:focus,.pagination>.active>span:focus{z-index:2;color:#fff;cursor:default;background-color:#428bca;border-color:#428bca}.pagination>.disabled>span,.pagination>.disabled>span:hover,.pagination>.disabled>span:focus,.pagination>.disabled>a,.pagination>.disabled>a:hover,.pagination>.disabled>a:focus{color:#777;cursor:not-allowed;background-color:#fff;border-color:#ddd}.pagination-lg>li>a,.pagination-lg>li>span{padding:10px 16px;font-size:18px}.pagination-lg>li:first-child>a,.pagination-lg>li:first-child>span{border-top-left-radius:6px;border-bottom-left-radius:6px}.pagination-lg>li:last-child>a,.pagination-lg>li:last-child>span{border-top-right-radius:6px;border-bottom-right-radius:6px}.pagination-sm>li>a,.pagination-sm>li>span{padding:5px 10px;font-size:12px}.pagination-sm>li:first-child>a,.pagination-sm>li:first-child>span{border-top-left-radius:3px;border-bottom-left-radius:3px}.pagination-sm>li:last-child>a,.pagination-sm>li:last-child>span{border-top-right-radius:3px;border-bottom-right-radius:3px}

.legenda {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 5px;
}

.trava-digitacao { 
    position: absolute;
    background-color: transparent;
    width: 100%;
    height: 100%;
    z-index: 9;
    cursor: wait;
}

.titulo-ninja {
    font-size: 17px;

}
.icone-titulo-ninja {
    margin-right: 5px;
    font-size: 21px;
}

.seletor-habilidade {
    padding: 8px 14px;
    border-radius: 10px;
}

.seletor-habilidade.escolhido {
    background-color: #f0f0f0;
}

@media screen and (max-width: 768px) {
    .cabecalho-principal {
        font-size: 60px !important;
    }

    .h1-lg {
        font-size: 24px;
        text-align: left;
    }

    .hidden-mobile {
        display: none;
    }

    .ct-label.ct-horizontal.ct-end {
        font-size: 9px;
    }
}

@media screen and (max-width: 1280px) {

    .cabecalho-principal {
        font-size: 60px  !important;
    }

    .hidden-md {
        display: none;
    }

    .ct-label.ct-horizontal.ct-end {
        font-size: 9px;
    }

    .mobile-text-left, .mobile-text-left .text-right {
        text-align: left !important;
    }

}

@media screen and (min-width: 768px) {
    .hidden-desktop {
        display: none;
    }
}



@media screen and (min-width: 1200px) {
    .container {
        min-width: 100%;
    }

    .h1-lg {
        font-size: 50px;
    }
}

@media screen and (min-width: 1500px) {
    .container {
        min-width: 1500px;
    }
}

.trofeu-vazio {
    color: rgb(255, 255, 255);
}

.trofeu-vazio2 {
    color: rgb(255, 255, 255, 0.1);
}

.treinar-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    margin-right: -29px;
    top: 0px;
    left: 0px;
}

#topnav .topbar-main .navbar-right {
    float: inherit;
}

.ribbon-right {
    position: absolute;
    right: -4px !important;
    left: unset;
    top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;
    font-family: "Poppins", sans-serif;
}

.ribbon-right span {
    font-size: 10px;
    font-weight: bold;
    color: #000;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 100px;
    display: block;
    -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
    background: #348cd4;
    background: -webkit-gradient(linear, left top, left bottom, from(#d6c010), to(#fee72a));
    background: linear-gradient(#d6c010 0%, #fee72a 100%);
    position: absolute;
    top: 19px;
    letter-spacing: 1px;
    left: -4px;
}

.ribbon-right span::before {
    content: "";
    position: absolute;
    left: 0;
    top: 100%;
    z-index: -1;
    border-left: 3px solid #d6c010;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #d6c010;
}

.ribbon-right span::after {
    content: "";
    position: absolute;
    right: 0;
    top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-right: 3px solid #d6c010;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #d6c010;
}

.badge-premium {
    background-color: #ffd700;
    color: #000;
    position: relative;
    left: -75px;
    top: -5px;
    border-radius: 0 0 .25rem .25rem;
}

.badge-premium-limpo {
    background-color: #ffd700;
    color: #000;
}

.badge-teste-gratis {
    left: -135px;
}

.progress {
    background-color: #d6d6d6;
}

.progress-light {
    background-color: #fff;
}

.ct-velocidade {
    stroke: #5a8941;
    fill: #b9eec5;
    /* stroke-width: 10px; */
    /* stroke-linecap: round; */
}
.ct-precisao {
    stroke: #d70206;
    fill: #efd9d9;
    /* stroke-width: 10px; */
    /* stroke-linecap: round; */
}

.inline-block {
    display: inline-block;
}

.widget-box-site {
    background-color: #7f0b8cc4;
    border: 1px solid #ff03df;
}

.widget-box-site .bg-icon {
    background-color: #1f002c69;
}

.btn-site {
    color: #fff;
    background-color: #1f002c69;
    border: 1px solid #ff03df
}

#topnav .navigation-menu > li > a i,
#topnav .navigation-menu > li > a {
    color: #FFF;
}

.texto-claro > .highlighted {
    background-color: #060606;
}

.bg-roxo-claro {
    background-color: #ff03df;
}

.titulo-textos-site {
    
    margin-top: 0;
    margin-bottom: 4px;
}
.textos-site {
    margin-bottom: 0;
}



.wrapper-site {
    padding-top: 80px !important;
    background-color: #580464;
    background-image: url(/img/bg-pattern.png);
    background-position: top;
    background-repeat: repeat;
    /* background-size: cover; */
    min-height: 720px;
    padding-bottom: 0px;
    /* background-blend-mode: soft-light; */
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.cabecalho-principal {
    font-size: 60px;
}

.wrapper-site-touch-typing {
    padding-top: 60px;
    background-color: #580464;
    background-image: url(/img/pexels-cottonbro-8720598-2.png);
    background-position: left;
    background-size: contain;
    min-height: 720px;
    padding-bottom: 0px;
    background-blend-mode: darken;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    background-repeat: no-repeat;
}

.wrapper-site-qwerty {
    padding-top: 60px;
    background-color: #580464;
    background-image: url(/img/pexels-cottonbro-8720619-p.png);
    background-position: right;
    background-size: contain;
    min-height: 720px;
    padding-bottom: 0px;
    background-blend-mode: darken;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    background-repeat: no-repeat;
}

.icone-categoria-conclusao {
    height: 80px;
    width: 80px;
    line-height: 76px;
    text-align: center;
    border-radius: 50%;
    -moz-border-radius: 50%;
    background-clip: padding-box;
    background-color: #7f0b8c73;
    margin-right: 20px;
}

.btn-light.btn-bordered {
    border: 2px solid #fff !important;
}

.btn-light.btn-bordered:hover {
    background-color: #eef1f3 !important;
    color: #212529 !important;
}

.text-justify {
    text-align: justify;
}

.cursor-blinking {
    animation: blinker 1s linear infinite;

}

.bg-body-claro {
    background-color: #f7f7f7;
}

.mb-90 {
    margin-bottom: 90px !important;
}

.form-control-roxo {
    background: #39048d5c; color: #fff;
}

.custom-control-input:checked ~ .custom-control-label-roxo::before {
    background: #39048d5c !important; border-color: #fff !important;
}

.card-roxo {
    border-color: #e504cc !important;
    background-color: transparent !important;
}

.ninja {
    color: #ed1c33 !important;
    background: #fff;
    border-radius: 12px;
    display:inline-block;
}

.notebook {
    width: 100%;
}

.video-notebook {
    width: 100%;
}


@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.bg-success-light {
    background-color: rgba(191, 232, 142, 0.25) !important;
}

.bg-warning-light {
    background-color: rgba(232, 210, 142, 0.25) !important;
}

.bg-primary-light {
    background-color: rgba(167, 159, 207, 0.25) !important;
}

.bg-info-light {
    background-color: rgba(166, 221, 243, 0.25) !important;
}

.bg-danger-light {
    background-color: rgba(243, 166, 166, 0.25) !important;
}

.bg-amarelo {
    background-color: rgba(255, 227, 0) !important;
}

.custom-element-lightbox-modal {
    border: 6px solid red;
}

.widget-box-three .curso {
    height: 80px;
    width: 80px;
}

[ng-cloak] {
    display: none !important;
}